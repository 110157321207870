<template>

  <div class="content">
    <div class="main_img">
      <warning-for-sample></warning-for-sample>
      <swiper :options="swiperOption">
        <swiper-slide>
          <img src="../../assets/images/main/ptn/main01.jpg" alt="" style="width: 100%">
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/images/main/ptn/main02.jpg" alt="" style="width: 100%">
        </swiper-slide>
        <swiper-slide>
          <img src="../../assets/images/main/ptn/main03.jpg" alt="" style="width: 100%">
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

    <!--모바일-->
    <div class="sub_mobile_menus">

      <router-link to="/front/casino" tag="div" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/casino.png" alt="">
      </router-link>
      <router-link to="/front/slot" tag="div" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/slot.png" alt="">
      </router-link>
      <router-link tag="div" to="/front/minigame/next_ring1m" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/minigame.png" alt="">
      </router-link>
      <!--      <router-link tag="div" to="" class="mtime" @click.native="alertholdem">-->
      <!--        <img src="../../assets/images/bg/mobile_menu/ptn/holdem.png" alt="">-->
      <!--      </router-link>-->
      <router-link tag="div" to="/front/sports" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/sports.png" alt="">
      </router-link>
      <router-link tag="div" to="/front/inplay" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/inplay.png" alt="">
      </router-link>
      <router-link tag="div" to="/front/sports_special" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/special.png" alt="">
      </router-link>

      <router-link tag="div" to="/front/event" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/event.png" alt="">
      </router-link>

      <router-link tag="div" to="/front/notice" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/notice.png" alt="">
      </router-link>
      <router-link tag="div" to="" @click.native="weekpayback" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/weekpayback.png" alt="">
      </router-link>


      <router-link tag="div" to="/front/culcheck" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/culcheck.png" alt="">
      </router-link>
      <router-link tag="div" to="/front/refusers" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/recmember.png" alt="">
      </router-link>
      <router-link tag="div" to="/front/message" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/message.png" alt="">
      </router-link>
      <router-link tag="div" to="/front/sportstv" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/sportstv.png" alt="">
      </router-link>


      <router-link tag="div" to="/front/sportsresult" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/gameresult.png" alt="">
      </router-link>
      <router-link tag="div" to="/front/sportsbethistory" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/betinfo.png" alt="">
      </router-link>

      <router-link tag="div" to="/front/freeboard" class="mtime">
        <img src="../../assets/images/bg/mobile_menu/ptn/freeboard.png" alt="">
      </router-link>


      <!--      <router-link tag="div" to="" class="mtime">-->
      <!--        <img src="../../assets/images/bg/mobile_menu/ptn/commingsoon.png" alt="">-->
      <!--      </router-link>-->
      <!--      <router-link tag="div" to="" class="mtime">-->
      <!--        <img src="../../assets/images/bg/mobile_menu/ptn/commingsoon.png" alt="">-->
      <!--      </router-link>-->
      <!--      <router-link tag="div" to="" @click.native="" class="mtime">-->
      <!--        <img src="../../assets/images/bg/mobile_menu/ptn/daypayback.png" alt="">-->
      <!--      </router-link>-->
      <!--      <router-link tag="div" to="" @click.native="" class="mtime">-->
      <!--        <img src="../../assets/images/bg/mobile_menu/ptn/weekpayback.png" alt="">-->
      <!--      </router-link>-->


      <!--      <router-link tag="div" to="/front/tgame/hilow10s" class="mtime">-->
      <!--        <img src="../../assets/images/bg/mobile_menu/ptn/tgame.jpg" alt="">-->
      <!--      </router-link>-->
      <!--      <router-link tag="div" to="/front/coupon" class="mtime">-->
      <!--        <img src="../../assets/images/bg/mobile_menu/ptn/coupon.jpg" alt="">-->
      <!--      </router-link>-->


      <!--      <router-link tag="div" to="/front/refusers" class="mtime">-->
      <!--        <img src="../../assets/images/bg/mobile_menu/ptn/recmember.jpg" alt="">-->
      <!--      </router-link>-->


      <!--      <router-link tag="div" to="" class="mtime">-->
      <!--        <img src="../../assets/images/bg/mobile_menu/ptn/commingsoon.jpg" alt="">-->
      <!--      </router-link>-->
      <!--      <router-link tag="div" to="" class="mtime">-->
      <!--        <img src="../../assets/images/bg/mobile_menu/ptn/commingsoon.jpg" alt="">-->
      <!--      </router-link>-->
    </div>


    <!--2줄 이미지-->
    <div class="nj_sub">
      <div class="d1 pc">
        <router-link tag="div" to="/front/casino" class="dimg l"><img src="../../assets/images/main/ptn/apmain01.jpg">
        </router-link>
        <router-link tag="div" to="/front/slot" class="dimg r"><img src="../../assets/images/main/ptn/apmain02.jpg">
        </router-link>
      </div>
      <div class="d2 pc">
        <router-link tag="div" to="/front/sports" class="dimg l"><img src="../../assets/images/main/ptn/apmain03.jpg">
        </router-link>
        <router-link tag="div" to="/front/minigame/next_ring1m" class="dimg m"><img
            src="../../assets/images/main/ptn/apmain04.jpg"></router-link>
        <router-link tag="div" to="/front/inplay" class="dimg r"><img src="../../assets/images/main/ptn/apmain06.jpg">
        </router-link>
      </div>
      <div class="d3">
        <div class="dcont">
          <div class="title">
            <span>공지사항</span>
            <span>+ More</span>
          </div>
          <div class="items">
            <router-link tag="div" :to="{path: '/front/notice', query: {id:item.id,t: new Date().getTime()}}"
                         v-for="item in noticeList"
                         class="itm">
              {{ item.title }}
            </router-link>
          </div>
        </div>
        <div class="dcont">
          <div class="title">
            <span>이벤트</span>
            <span>+ More</span>
          </div>
          <div class="items">
            <router-link tag="div" :to="{path: '/front/event', query: {id:item.id,t: new Date().getTime()}}"
                         v-for="item in eventList"
                         class="itm">
              {{ item.title }}
            </router-link>
          </div>
        </div>
        <div class="dcont">
          <div class="title">
            <span>출금현황</span>
            <span></span>
          </div>
          <div class="items">
            <div class="itm" v-for="item in exchangeList">
              <div style="width: 33.3%;text-align: left">{{ item.user.username }}</div>
              <div style="width: 33.3%;text-align: center">{{ item.amount|comma }} 원</div>
              <div style="width: 33.3%;text-align: right">{{ item.createTime|datef('MM월DD일') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>


    <my-unchecked-message-comp></my-unchecked-message-comp>
    <popup-comp></popup-comp>

  </div>

</template>

<script>

import Left from "@/views/afront/Left.vue";
import Header from "@/views/afront/Header.vue";
import Right from "@/views/afront/Right.vue";
import Footer from "@/views/afront/Footer.vue";
import MyUncheckedMessageComp from "@/views/afront/notice/MyUncheckedMessageComp.vue";
import PopupComp from "@/views/afront/notice/PopupComp.vue";
import {getEvent, getNoticeList, getRecentlyExchange, weekpayback} from "@/network/userRequest";
import {getRecentlyEndGames, getRecentlyGames} from "@/network/sportsRequest";
import sportsConst from "@/common/sportsConst";
import inplayConst from "@/common/inplayConst";
import WarningForSample from "@/views/afront/zero/WarningForSample.vue";
import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "@/store/mutation-types";

export default {
  name: "Main",
  components: {WarningForSample, PopupComp, MyUncheckedMessageComp, Footer, Right, Header, Left},
  data() {
    return {
      sportsConst,
      inplayConst,
      noticeList: [],
      eventList: [],
      soccer: [],
      base: [],
      basket: [],
      hokey: [],
      esports: [],
      exchangeList: [],
      vol: [],
      swiperOption: {
        pagination: {el: '.swiper-pagination'},
        autoplay: {},
        disableOnInteraction: false,
        delay: 2000,
      },
    }
  },
  methods: {
    alertholdem() {
      this.$swal({
        title: '홀덤은 준비중에 있습니다',
        type: 'error',
        showCancelButton: false,
        showConfirmButton: true
      })
      return false;
    },

    weekpayback() {
      this.$store.commit(RECEIVE_SHOW_LOADING)
      weekpayback().then(res => {
        this.$store.commit(RECEIVE_HIDE_LOADING)
        if (res.data.success) {
          this.$store.dispatch('actionUserInfo')
          this.$swal({
            title: '주간 페이백 지급 성공',
            type: 'success',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        } else {
          this.$swal({
            title: res.data.msg,
            type: 'error',
            confirmButtonText: '확인',
            showCancelButton: false,
            showConfirmButton: true
          })
        }
      })
    },


  },
  created() {

    getNoticeList(100).then(res => {
      this.noticeList = res.data.data
    })
    getEvent(1, 100).then(res => {
      this.eventList = res.data.data
    })
    this.$store.state.userinsertagentcode = '';
    this.$store.state.ischeckedagentcode = false;
    getRecentlyExchange().then(res => {
      if (res.data.success) {
        this.exchangeList = res.data.data
      }
    })
  }
}
</script>

<style scoped>
@import url("../../assets/css/front/afrontmain.css");

.content .nj_sub {
  width: 100%;
  margin-top: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 2px 2px 2px #dcdcdc, 2px -2px 2px #dcdcdc, -2px 2px 2px #dcdcdc, -2px -2px 2px #dcdcdc;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

}

.content .nj_sub .d1 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  cursor: pointer;
  animation: zoomIn;
  animation-duration: .3s;
}

.content .nj_sub .d1 .dimg {
  width: 50%;
}

.content .nj_sub .d1 img {
  width: 100%;
  animation: zoomIn;
  animation-duration: .3s;
}

.content .nj_sub .d2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 10px;
  cursor: pointer;
  animation: zoomIn;
  animation-duration: .3s;
}

.content .nj_sub .d2 .dimg {
  width: 50%;
}

.content .nj_sub .d2 img {
  width: 100%;
  animation: zoomIn;
  animation-duration: .3s;
}


.content .nj_sub .d3 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: 1rem;
  animation: zoomIn;
  animation-duration: .3s;
}

.content .nj_sub .d3 .dcont {
  width: 33%;
  max-height: 30rem;
  background-color: #f6f6f6;
  box-sizing: border-box;
  --padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.content .nj_sub .d3 .dcont .title {
  width: 100%;
  height: 4.5rem;
  --background-color: #e96e1e;
  background: linear-gradient(180deg, #e10d0e, #8f0406, #e10d0e);
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  font-size: 1.6rem;
  color: #fff9ff;
  font-weight: bold;

}

.content .nj_sub .d3 .dcont .items {
  margin: .5rem 0;
  width: 100%;
  padding: 1rem;
  text-align: left;
  max-height: 22rem;
  overflow-x: hidden;
  overflow-y: scroll;
}

.content .nj_sub .d3 .dcont .items .itm {
  width: 100%;
  height: 3rem;
  --border: 1px solid #000000;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;
  font-size: 1.5rem;
  font-weight: bold;
  color: #3b3b3b;
  cursor: pointer;

}

.content .nj_sub .l {
  margin-right: .5rem;
}

.content .nj_sub .m {
  margin-left: .5rem;
  margin-right: .5rem;
}

.content .nj_sub .r {
  margin-left: .5rem;
}
</style>